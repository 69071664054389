import React from 'react'
import Helmet from 'react-helmet'
import { Layout, Heading, Row, Column, Image, Section, Text } from 'components'
import Blog from 'images/blogs/blog-13.png'
import 'components/css/stories.css'

const SITE_META = [
  {
    name: 'description',
    content: 'Mabilis, Madali, Mura',
  },
  {
    name: 'keywords',
    content:
      'digipay, fintech, e-payments, bills payment, ' +
      'mobile money, e-load, e-pins, microinsurance',
  },
  {
    property: 'og:url',
    content: `https://${process.env.GATSBY_REACT_APP_HOSTNAME}/stories/TipsParaMagingExcitingAngPasko`,
  },
  {
    property: 'og:type',
    content: 'website',
  },
  {
    property: 'og:title',
    content: 'Tips Para Maging Exciting Ang Pasko With Digipay',
  },
  {
    property: 'og:description',
    content: 'Alamin kung paano maa-achieve iyan as a Digipay Agent.',
  },
  {
    property: 'og:image',
    content: Blog,
  },
]

const ReasonsWhy = () => (
  <Layout>
    <Helmet
      title="Tips Para Maging Exciting Ang Pasko With Digipay"
      meta={SITE_META}
    >
      <html lang="en" />
    </Helmet>

    <Row>
      <Column size="df:[100%] lg:[col-6]" py="sp4" px="sp3" m="auto">
        <Image width="100%" mb="sp3" src={Blog} />
        <Heading
          className="blog__title"
          mb="sp4"
          fontWeight="bold"
          textAlign="center"
        >
          TIPS PARA MAGING EXCITING ANG PASKO WITH DIGIPAY
        </Heading>

        <Section mb="sp4">
          <Text>
            Ho-Ho-Ho-Ho! Merry Christmas, mga ka-Digipay. Gusto mo bang maging
            exciting ang Pasko? Narito ang tips kung paano mo maa-achieve ‘yan!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            PALAMUTI IS THE KEY
          </Heading>
          <Text>
            Meron ka bang Christmas lights o kahit anong decoration para lalong
            maging kapansin-pansin ang iyong Digitindahan? Isa itong magandang
            strategy upang maka-attract ng suki. Hindi naman kailangang bongga,
            basta makita nila ang effort mo. That’s the Christmas spirit!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            SMILE AND SAY THANK YOU
          </Heading>
          <Text>
            Ang pagiging friendly at mabait sa iyong mga suki ay isang magandang
            katangian bilang Digipay Agent. Especially this Christmas season,
            mas malaki ang chance na bumalik ang mga tao sa’yo dahil bonus
            season din. Imagine dahil lang sa ngiti mo padami na nang padami ang
            iyong Digipay suki. Ang saya!
          </Text>
        </Section>

        <Section mb="sp4">
          <Heading mb="sp2" fontSize="fsize-3" fontWeight="bold">
            REFER MORE, EARN MORE
          </Heading>
          <Text>
            Kung matagal ka nang Digipay Agent, siguradong nakapag-refer ka na
            ng friend using your referral code. Pero kung hindi pa, gawin mong
            exciting ang Pasko because you can earn 100 Digipay credits for
            every successful sign-up of your referral. Oh ‘di ba, dagdag kita
            din!
          </Text>

          <Text>
            Ready ka na bang maging exciting ang Christmas? Hanggang sa muli,
            ka-Digipay!
          </Text>
        </Section>
      </Column>
    </Row>
  </Layout>
)

export default ReasonsWhy
